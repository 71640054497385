<template>
  <div class="merge-alarm-statistics" ref="mergeAlarmStatistics">
    <div class="form-area" ref="form">
      <formSearch
        :alarmStatusList="alarmStatusList"
        :alarmOptions="alarmOptions"
        @getFormVal="onSubmit"
      >
        <el-button
          size="small"
          type="primary"
          :disabled="tableData.length === 0"
          :loading="downLoadStatus"
          @click="exportData"
          >导出</el-button
        >
      </formSearch>
    </div>
    <el-table id="outputTable" :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="alarmType"
        label="报警类型"
        width="160"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span class="alarmName" @click="showAlarmDetail(scope.row)">{{
            alarmTypeObj[Number(scope.row.alarmType)]
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="alarmLevel" label="报警等级">
        <template slot-scope="scope">
          {{ alarmLevelObj[Number(scope.row.alarmLevel)] }}
        </template>
      </el-table-column>
      <el-table-column prop="cph" label="车牌号"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属车队"
        width="160"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="driverName" label="驾驶员姓名"></el-table-column>
      <el-table-column prop="alarmSource" label="报警来源">
        <template slot-scope="scope">{{
          alarmSourceObj[Number(scope.row.alarmSource)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="alarmSignTime"
        width="180"
        label="报警发生时间"
      ></el-table-column>
      <el-table-column
        prop="alarmEndTime"
        width="180"
        label="报警结束时间"
      ></el-table-column>
      <el-table-column
        prop="percentage"
        label="报警最高车速/限速值"
        width="160"
      >
        <template slot-scope="scope">
          {{ computedValue(scope.row) }}
        </template>
      </el-table-column>

      <el-table-column prop="alarmStatus" label="报警状态">
        <template slot-scope="scope">
          {{ statusObj[Number(scope.row.alarmStatus)] }}
        </template>
      </el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 详情弹窗组件 -->
    <el-dialog
      title="报警详情"
      v-if="detailDialog"
      :visible.sync="detailDialog"
      width="65%"
    >
      <div class="body">
        <alarm-dialog
          :detailData="alarmItem"
          :alarmLevelObj="alarmLevelObj"
          :disposeTypeObj="disposeTypeObj"
          :disposeWayObj="disposeWayObj"
          :alarmTypeObj="alarmTypeObj"
          ref="alarmDia"
          @cancelDialog="cancelDialog"
        ></alarm-dialog>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="default" @click="cancelDialog"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="cancelDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import formSearch from "./component/formSearch.vue"
import alarmDetailDialog from "./component/alarmDetailDialog";
import {
  queryCheckMergeAlarmPageListAPI,
} from "@/api/lib/gps-api.js";
import {
  queryDictsByCodes
} from '@/api/lib/api.js';
import {
  formatDict,
} from '@/common/utils/index'
export default {
  components: {
    formSearch,
    "alarm-dialog": alarmDetailDialog
  },
  data () {
    return {
      tableData: [],
      tableHeight: 0,
      total: 1,
      form: {
        pageSize: 10,
        currentPage: 1,
      },
      downLoadStatus: false,
      detailDialog: false,
      alarmItem: null,
      alarmLevelObj: {},//报警等级
      alarmSourceObj: {},//报警来源
      disposeTypeObj: {},
      disposeWayObj: {},
      //报警状态 （1开始2结束3持续）
      alarmStatusList: [
        {
          dictValue: "开始",
          dictCode: 1
        },
        {
          dictValue: "已结束",
          dictCode: 2
        },
        {
          dictValue: "持续中",
          dictCode: 3
        },
      ],
      alarmOptions: [
        {
          dictValue: "疲劳驾驶报警",
          dictCode: 1001
        },
        {
          dictValue: "超速报警",
          dictCode: 2012
        },
        {
          dictValue: "路网图超速报警",
          dictCode: 2051
        }
      ],
      alarmTypeObj: {},//报警类型
      statusObj: {},//报警状态
    }
  },
  created () {
    this.getDicts();
    this.statusObj = formatDict(this.alarmStatusList);
    this.alarmTypeObj = formatDict(this.alarmOptions);
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    computedValue (row) {
      //疲劳驾驶展示-
      if (row.alarmType == 1001) {
        return "-"
      } else {
        return `${row.velocity}/${row.limitValue}`
      }
    },
    cancelDialog () {
      this.detailDialog = false;
    },
    showAlarmDetail (row) {
      this.alarmItem = { ...row };
      this.detailDialog = true;
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page;
      this.getDataList();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size;
      this.getDataList();
    },
    getDataList () {
      queryCheckMergeAlarmPageListAPI(this.form).then(res => {
        if (res.code === 1000) {
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      })
    },
    /** 数据为空默认显示- */
    formatNull (row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : cellValue
    },
    onSubmit (val) {
      this.form = { ...val };
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.getDataList();
    },
    exportData () {

    },
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.mergeAlarmStatistics.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        formHeight -
        paginationHeight -
        10;
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({
        parentCodes:
          'BJDJ,BJLY,CLLX,BJCLFS'
      }).then((res) => {
        if (res.code === 1000) {
          // this.dictList = {
          //   alarmLevelList: res.data.BJDJ,
          // }
          this.alarmLevelObj = formatDict(res.data.BJDJ);
          this.alarmSourceObj = formatDict(res.data.BJLY);
          this.disposeTypeObj = formatDict(res.data.CLLX);
          this.disposeWayObj = formatDict(res.data.BJCLFS);
        }
      }).then(() => {
        // this.getAlarmTypeFn()
        // this.getAlarmData()
      })
    }
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  }
}
</script>
<style lang="scss" scoped>
.merge-alarm-statistics {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  @include themify() {
    .alarmName {
      color: themed('b4');
      cursor: pointer;
    }
  }
}
</style>