<template>
  <div class="alarm-dialog">
    <el-tabs v-model="activeName">
      <el-tab-pane label="报警详情" name="first">
        <el-row :gutter="40">
          <el-col :span="12">            
            <div class="information">
              <div class="head">
                <p>
                  <span>车牌号：</span>
                  <span>{{ detailForm.cph }}</span>
                </p>
                <p>
                  <span>所属车队：</span>
                  <span>{{ detailForm.companyName }}</span>
                </p>
                <p class="noWrap">
                  <span>报警类型：</span>
                  <span class="sign">
                    {{ alarmTypeObj[Number(detailForm.alarmType)] }}
                  </span>
                </p>
                <p>
                  <span>报警等级：</span>
                  <span class="sign">
                    {{ alarmLevelObj[Number(detailForm.alarmLevel)] }}
                  </span>
                </p>
              </div>
              <div class="body">
                <el-table
                  :data="tableDataObj.detailList"
                  height="calc(45vh - 118px)"
                  class="no-blank alarm-table"
                  ref="tableName"
                  @row-click="RowDetailFun"
                >
                  <el-table-column type="index" label="序号"></el-table-column>
                  <el-table-column
                    prop="time"
                    label="报警时间"
                    width="180"
                    show-overflow-tooltip
                  >
                    <template slot-scope="scope">
                      <span>{{ computedTime(scope.row.time) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="speedingVelocity"
                    label="速度/限速（km/h)"
                    width="160"
                  >
                    <!-- <template slot-scope="scope">
                      <span
                        >{{ scope.row.velocity ? scope.row.velocity : '-' }}/{{
                          scope.row.limitValue ? scope.row.limitValue : '-'
                        }}</span
                      > 
                    </template> -->
                  </el-table-column>
                  <el-table-column
                    v-if="
                      detailData.alarmType === 2012 ||
                      detailData.alarmType === 2051
                    "
                    label="超速比例"
                    width="80"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        scope.row.velocity
                          | filterProportion(scope.row.limitValue)
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="vehStatus"
                    label="报警状态"
                    width="100"
                    show-overflow-tooltip
                  >
                    <template slot-scope="scope">
                      <span>{{ computedStatus(scope.$index) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="vehStatusComment"
                    label="车辆状态"
                    width="180"
                    show-overflow-tooltip
                  ></el-table-column>
                </el-table>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <p class="title">轨迹</p>
            <div class="map-style">
              <searchMap ref="searchMap" :locatePointShow="locatePointShow" />
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="报警处理" name="second">
        <div class="alarm-dia">
          <div>
            <div class="scroll">
              <p class="title">处理记录</p>
              <div class="record">
                <el-table
                  :data="tableDataObj.disposeAlarmData"
                  height="calc(45vh - 118px)"
                  class="no-blank"
                >
                  <el-table-column type="index" label="序号"></el-table-column>
                  <el-table-column
                    prop="operator"
                    label="处理人姓名"
                  ></el-table-column>
                  <el-table-column
                    prop="disposeTime"
                    label="处理时间"
                    width="180"
                  >
                    <template slot-scope="scope">
                      <span>{{ computedTime(scope.row.disposeTime) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="disposeType" label="处理类型">
                    <template slot-scope="scope">
                      {{ disposeTypeObj[Number(scope.row.disposeType)] }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="disposeWay" label="处理方式">
                    <template slot-scope="scope">
                      {{ disposeWayObj[Number(scope.row.disposeWay)] }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="disposeDescription"
                    label="处理内容"
                    :show-overflow-tooltip="true"
                  ></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import {
  queryAlarmByAlarmNoAPI,
  queryAlarmDetailListAPI,
  queryAlarmTraceAPI,
} from "@/api/lib/gps-api.js";
import searchMap from '@/views/monitor/alarmSetting/components/map.vue'
import { formatDate } from '@/common/utils/index.js'
export default {
  components: {
    searchMap,
  },
  props: ['detailData', 'alarmLevelObj', 'disposeTypeObj', 'disposeWayObj', 'alarmTypeObj'],
  data () {
    return {
      fileForm: [],
      activeName: 'first',
      detailForm: {
        cph: '',
        companyName: '',
        driverName: '',
        drivingLicense: '',
        alarmName: '',
        alarmLevel: ''
      },
      tableDataObj: {
        detailList: [],
        disposeAlarmData: []
      },
      locatePointShow: null,//报警点地图定位点
      system: sessionStorage.getItem('system').toString(),
    }
  },
  filters: {
    filterProportion (item, limitValue) {
      let str = "-";
      if (limitValue && limitValue !== 0) {
        const poportion = (item - limitValue) / limitValue;
        str = `${Math.round(poportion * 100)}%`
      }
      return str
    }
  },
  methods: {
    computedTime (time) {
      return formatDate(time);
    },
    computedStatus (index) {
      const length = this.tableDataObj.detailList.length;
      const status = this.detailData.alarmStatus;
      //报警状态 （1开始2结束3持续） 
      if (this.tableDataObj.detailList.length == 1 && status == 2) {
        return "结束报警"
      } else {
        if (index === 0) {
          return "开始报警"
        } else if (status == 2 && index == length - 1) {
          return "结束报警"
        } else {
          return '持续报警'
        }
      }
    },
    //获取报警前后几分钟的轨迹
    getAlarmTrace () {
      const data = {
        alarmNo: this.detailData.alarmNo
      };
      queryAlarmTraceAPI(data).then(res => {
        this.$refs.searchMap.onTrailPlay(res)
      })
    },
    RowDetailFun (row) {
      this.locatePointShow = {
        ...row,
        alarmSignTime: this.computedTime(row.time)
      };//地图轨迹标点      
    },
    //获取报警列表信息/处理详情
    getTableData () {
      const data = {
        system: this.system,
        alarmStatus: this.detailForm.alarmStatus,
        beginTime: this.detailForm.alarmSignTime,
        endTime: this.detailForm.alarmEndTime,
        vehicleNo: this.detailForm.vehicleNo,
        alarmType: this.detailForm.alarmType
      }
      queryAlarmDetailListAPI(data).then(res => {
        if (res.code === 1000) {
          this.tableDataObj = res.data;
          if (res.data.detailList.length) {
            const locatePointData = {
              ...res.data.detailList[0],
              alarmSignTime: this.computedTime(res.data.detailList[0].time)
            }
            this.RowDetailFun(locatePointData);//标记第一个点
          }
          this.$nextTick(() => {
            this.$refs.tableName.doLayout();
          })
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    //获取详情
    getDetail () {
      const queryData = {
        alarmNo: this.detailData.alarmNo,
      }
      queryAlarmByAlarmNoAPI(queryData).then(res => {
        if (res.code === 1000) {
          this.detailForm = res.data;
          this.getTableData();
        } else {
          this.$message.warning(res.msg);
        }
      })
    },
  },
  mounted () {
    this.getDetail();
    this.getAlarmTrace();
  }
}
</script>
<style lang="scss" scoped>
/deep/.deal-form {
  .el-form-item {
    &:nth-of-type(1),
    &:nth-of-type(2) {
      width: 45%;
    }
    &:last-of-type {
      width: 100%;
      display: flex;
      .el-form-item__content {
        flex: 1;
      }
    }
  }
}
.alarm-dialog {
  .map-style {
    width: 100%;
    height: calc(45vh - 24px);
  }
  .null-tips {
    width: 100%;
    line-height: 16vh;
    text-align: center;
    color: #999;
  }
  .mediaStyle {
    width: 45%;
    height: 50%;
    display: inline-block;
    margin-right: 2%;
    img {
      width: 100%;
      height: 100%;
    }
    .video-player {
      width: 100%;
      height: 100%;
      border: 1px solid transparent;
      background: transparent;
    }
    /deep/.el-carousel {
      height: 100%;
      .el-carousel__container {
        height: 100%;
      }
    }
  }
  .title {
    margin-bottom: 6px;
  }
  .alarm-dia {
    // height: calc(79vh - 149px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    @include themify() {
      .fixed {
        flex: 1;
      }
      .scroll {
        overflow-y: auto;
      }
      .title {
        color: themed('n7');
        font-size: 14px;
        margin-bottom: 16px;
      }
      .header-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 6px;
        span {
          margin-right: 1vw;
        }
      }
      .information,
      .record,
      .deal {
        background-color: themed('n1');
      }
      .information {
        padding: 12px;
        margin-bottom: 16px;
        .head {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          color: themed('n8');
          font-size: 14px;
          margin-bottom: 16px;
          p:nth-of-type(1) {
            margin-bottom: 16px;
          }
          // p:nth-of-type(4n) {
          //   text-align: right;
          // }
        }
        .split-line {
          border-bottom: 1px solid;
          border-bottom-color: themed('n4');
          margin: 0 -12px 12px;
        }
      }
      .record {
        padding: 12px;
        margin-bottom: 16px;
      }
      .deal {
        padding: 16px;
      }
      .sign {
        color: themed('b4');
      }
      .pointer {
        cursor: pointer;
      }
    }
  }
  @include themify() {
    .alarmName {
      color: themed('b4');
      cursor: pointer;
    }
    .information {
      padding: 12px 0;
      margin-bottom: 6px;
      .head {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        color: themed('n8');
        font-size: 14px;
        margin-bottom: 16px;
        p:nth-of-type(1) {
          margin-bottom: 16px;
        }
        // p:nth-of-type(4n) {
        //   text-align: right;
        // }
      }
      .split-line {
        border-bottom: 1px solid;
        border-bottom-color: themed('n4');
        margin: 0 -12px 12px;
      }
    }
  }
}
</style>