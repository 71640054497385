<template>
  <div class="">
    <el-form
      class="demo-form-inline"
      ref="form"
      :model="form"
      label-position="left"
      label-width="82px"
      :inline="true"
      :rules="rules"
    >
      <el-form-item label="所属车队：">
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="true"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>

      <el-form-item label="车牌号：">
        <car-tree
          @getData="getVehIds"
          ref="carTree"
          :companyIdList="choosecph"
        ></car-tree>
      </el-form-item>

      <el-form-item label="报警类型：">
        <!-- <el-cascader
          v-model="form.alarmTypeList"
          :options="alarmOptions"
          :props="affirmAlarmProps"
          size="small"
          collapse-tags
          clearable
          filterable
        ></el-cascader> -->
        <el-select clearable multiple collapse-tags v-model="form.alarmTypeList" placeholder="请选择">
              <el-option
                v-for="item in alarmOptions"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
      </el-form-item>

      <el-form-item label="开始时间：" prop="beginTime">
        <el-date-picker
          v-model="form.beginTime"
          type="datetime"
          placeholder="选择开始时间"
          :clearable="false"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="结束时间：" prop="endTime">
        <el-date-picker
          v-model="form.endTime"
          type="datetime"
          placeholder="选择结束时间"
          :clearable="false"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="报警状态：">
        <el-select clearable v-model="form.alarmStatus" placeholder="请选择">
          <el-option
            v-for="item in alarmStatusList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="alarm-btn">
        <el-button size="small" type="primary" @click="search">查询</el-button>
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue';
import {
  checkTimeLimit,
  checkLimitDate,
  getStartDate,
  getCurentDate,
  formatDate,
} from '@/common/utils/index'
export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props:{
    alarmStatusList:{
      type:Array,
      default:()=>[]
    },
    alarmOptions:{
      type:Array,
      default:()=>[]
    },
  },
  mounted(){
    this.search();
  },
  data () {
    return {
      choosecph: [],
      downLoadStatus: false,
      form: {
        alarmTypeList:[],
        vehicleNos: [],
        companyIds: [],
        alarmStatus: "",
        beginTime: new Date(getStartDate()), // 开始时间,
        endTime:new Date(getCurentDate()), // 结束时间,
      },
      props: {
        label: 'name',
        children: 'zones',
        isLeaf: 'leaf'
      },
      rules: {

      },
      affirmAlarmProps: {
        value: 'value',
        label: 'label',
        children: 'children',
        multiple: true
      },
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
    }
  },
  methods: {
    // 获取车辆数据
    getVehIds (val) {
      this.form.vehicleNos = [...val];
    },
    // 获取车组数据
    getGroupIds (val) {
      this.form.companyIds = [...val];
    },
    search () {
      const data = {...this.form};
      data.beginTime = formatDate(this.form.beginTime);
      data.endTime = formatDate(this.form.endTime);
      this.$emit("getFormVal", data);
    },
  }
}
</script>